@media (prefers-reduced-motion: no-preference) {
  .header {
    animation: Background-slide infinite 30s ease-in-out;
  }
}

.header {
  background: linear-gradient(
    45deg,
    rgba(136, 255, 112, 1) 0%,
    rgba(6, 182, 142, 1) 50%,
    rgba(4, 220, 255, 1) 100%
  );
  background-size: 300%, 300%;
  min-height: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-left {
  left: 25px;
  position: absolute;
}

.header-right {
  right: 1%;
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 160px;
  justify-content: space-between;
  user-select: none;
}

.header-button {
  background-color: transparent;
  color: white;
  border-radius: 20px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  outline: none;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

@keyframes Background-slide {
  from {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  to {
    background-position: 0% 0%;
  }
}

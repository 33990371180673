h1 {
  color: #ffffff;
  user-select: none;
}

h2 {
  color: #ffffff;
  user-select: none;
  font-size: medium;
}

h3 {
  color: #ffffff;
  user-select: none;
}

p {
  color: #ffffff;
  user-select: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  pointer-events: none;
  user-select: none;
}

.App-body {
  background: #222222;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}

.App-main {
  margin-top: 120px;
  margin-bottom: 50px;
  background-color: #181818;
  width: 80%;
  height: 1000px;
  border-radius: 20px;
  border-width: 2;
  color: #ffffff;
}

.App-main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.App-main-header-right {
  position: absolute;
  right: 12%;
  margin-top: 75px;
  flex-wrap: nowrap;
}

.App-profile-name {
  margin-top: 100px;
}

.App-link {
  color: #61dafb;
}

.App-beta-text {
  background-color: transparent;
  color: white;
  border-radius: 20px;
  user-select: none;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  outline: none;
  font-size: 13px;
  padding: 3px 8px 3px 8px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}

.App-profile-photo-div {
  position: absolute;
  height: 200px;
  width: 200px;
  background-color: #141414;
  border-radius: 50%;
}

.App-profile-photo {
  border-radius: 50%;
}

.App-button {
  background-color: transparent;
  color: white;
  border-radius: 20px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  outline: none;
  font-size: 15px;
  padding: 5px 10px 5px 10px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  border-image-slice: 1;
}

.App-button:hover {
  color: #efefef;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(
        45deg,
        rgba(136, 255, 112, 1) 0%,
        rgba(6, 182, 142, 1) 50%,
        rgba(4, 220, 255, 1) 100%
      )
      border-box;
  background-size: 300%, 300%;
  border-color: transparent;
}

.App-button:active {
  background-color: transparent;
}

.App-button:focus {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: x-large;
  }

  h2 {
    font-size: large;
  }

  h3 {
    font-size: medium;
  }

  .App-button {
    background-color: transparent;
    border-radius: 20px;
    border-width: 2px;
    font-size: 13px;
    padding: 2px 10px 2px 10px;
  }

  .App-profile-photo-div {
    position: absolute;
    height: 150px;
    width: 150px;
    background-color: #141414;
    border-radius: 50%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-button:hover {
    animation: test 1s forwards;
  }
}

@keyframes test {
  from {
    background-position: 0% 0%;
  }
  to {
    background-position: 50% 50%;
    border-color: white;
  }
}
